import store from '@/store'
import router, {
    resetRouter
} from "@/router/index";
import axios from "axios";
import {
    Message,
    MessageBox
} from "element-ui";
import {
    clearSession,
    getSession
} from "@/utils/storage";
import qs from "qs"

const service = axios.create({
    baseURL: siteInfo.siteroot,
    timeout: 50000
});

// 添加请求拦截器
service.interceptors.request.use(
    (config) => {
        console.log(config,'禾匠拦截');
        const formData = new FormData();
        formData.append('timestamp', Date.parse(new Date()) / 1000);
        // 将其他请求参数添加到 FormData 中
        for (const key in config.data) {
            formData.append(key, config.data[key]);
        }
        //
        // config.data = {
        //     ...config.data,
        //     timestamp: Date.parse(new Date()) / 1000,
        // }
        config.headers.common["platform"] = siteInfo.platform;
        //config.data.timestamp = new Date();
        if (config.method == 'get') {
            config.params = config.data;
        }
        if (getSession("access_token")) {
            config.headers.common["access-token"] = `${getSession("access_token")}`;
        }
        return config;
    },
    (error) => {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

// 添加响应拦截器
service.interceptors.response.use(
    (response) => {
        // 对响应数据做点什么
        const res = response.data;
        if (res.code && res.code !== 0) {
            // `token` 过期或者账号已在别处登录
            if (res.code === 401 || res.code === 4001) {
                // 清除浏览器全部临时缓存
                clearSession();
                router.push("/login");
                store.commit('setMenuData', {})
                resetRouter() // 重置路由
                MessageBox.alert({
                    title: "提示",
                    message: '你已被登出，请重新登录'
                }).then(() => {
                }).catch(() => {
                });
            }
            //return Promise.reject(service.interceptors.response.error);
        }
        return response;
    },
    (error) => {
        console.log(error,'错误');
        if (error.response.status && error.response.status != 200) {
            Message.error("服务器返回" + error.response.status + '错误码');
        }
        // 对响应错误做点什么
        if (error.message.indexOf("timeout") != -1) {
            Message.error("网络超时");
        } else if (error.message == "Network Error") {
            Message.error("网络连接错误");
        }
        // MessageBox(error.response.data.msg, '错误提示', {
        // 	confirmButtonText: '确定',
        // });
        //	Message.alert(error.response.data.msg);
        return error.response;
        //return Promise.reject(error);
    }
);

export default service;
