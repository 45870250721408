<template>
  <div>
    <el-button type="text" @click="loadUser">{{ title ? title : user.username }}</el-button>
    <el-dialog title="用户信息" :visible.sync="is_show" width="40%" :append-to-body="true">
      <el-card v-loading="loading" element-loading-text="用户信息加载中..">
        <div style="padding: 5px">
          <el-descriptions :column="item.column?item.column:3" border :title="item.title" class="top-sm"
                           v-for="(item,index) in rdata" style="margin-top:20px">
            <el-descriptions-item v-for="(items,index) in item.list" :label="items.label"
                                  :span="items.span?items.span:1">{{ items.value }}
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </el-card>
    </el-dialog>
  </div>

</template>

<script>
export default {
  name: "app-user-info",
  props: {
    title: "",
    user: {
      type: Object,
      default: function () {
        return {
          username: "",
          id: ""
        };
      }
    }
  },
  data() {
    return {
      loading: false,
      is_show: false,
      rdata: {
        userInfo: {},
        user: {},
        company: {},
        person: {
          company: {}
        }
      }
    }
  },
  created() {

  },
  methods: {
    loadUser() {
      this.is_show = true;
      this.loading = true;
      this.$api.user.getInfo({user_id: this.user.id}, rdata => {
        this.loading = false;
        if (rdata.code != 0) {
          this.is_show = false;
          return this.$core.message.error(rdata.msg);
        }
        this.rdata = rdata.data;
      })
    }
  }
}
</script>

<style scoped>
/*.el-descriptions {*/
/*  display: flex;*/
/*  justify-content: space-between;*/
/*  align-items: center;*/
/*  margin-bottom: 10px;*/
/*  margin-top: 12px*/
/*}*/

.el-descriptions__header.top-sm {
  margin-top: 12px
}
</style>