import Vue from "vue"
import App from "./App.vue"
import store from "./store"
import router from "./router"
//import "@/public/siteinfo";
import VueParticles from "vue-particles"
import ElementUI from "element-ui"
import "@/assets/css/base.scss"
import "@/assets/font/iconfont.css"
import "@/assets/css/7ims.scss"
import VCharts from "v-charts"
import Echarts from "echarts"
import api from "@/api"
import appTable from "@/components/common/app-table"
import appDiyForm from "@/components/common/app-diy-form"
import appDrawerTableVue from "@/components/common/app-drawer-table.vue";
import appUserInfo from "@/components/user/app-user-info.vue";
import "echarts-gl";
import utils from "@/utils";
import core from "@/utils/core";
import request from "@/utils/request_bd";
Vue.component('app-drawer-table', appDrawerTableVue);


Vue.use(ElementUI);
Vue.use(VueParticles);
Vue.use(VCharts);
Vue.component('app-table', appTable)
Vue.component('app-diy-form', appDiyForm)
Vue.component('app-user-info', appUserInfo)
Vue.config.productionTip = false;
Vue.prototype.bus = new Vue();
Vue.prototype.$echarts = Echarts;

Vue.prototype.$request = request;
Vue.prototype.$api = api;
Vue.prototype.$siteInfo = siteInfo;
Vue.prototype.$utils = utils;
Vue.prototype.$core = core;
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
