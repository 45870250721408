import Cookies from 'js-cookie';

// 1. 使用 js-cookie 模拟 localStorage
// 设置永久缓存
export function setLocal(key, val) {
    Cookies.set(key, JSON.stringify(val), { expires: 365 }); // 将对象转换为JSON字符串
}

// 获取永久缓存
export function getLocal(key) {
    const cookie = Cookies.get(key);
    return cookie ? JSON.parse(cookie) : null; // 将JSON字符串转换回对象
}

// 移除永久缓存
export function removeLocal(key) {
    Cookies.remove(key);
}

// 移除全部永久缓存
export function clearLocal() {
    const cookies = Cookies.get();
    for (let key in cookies) {
        Cookies.remove(key);
    }
}

// 2. 使用 js-cookie 模拟 sessionStorage
// 设置临时缓存
export function setSession(key, val) {
    Cookies.set(key, JSON.stringify(val)); // 将对象转换为JSON字符串
}

// 获取临时缓存
export function getSession(key) {
    const cookie = Cookies.get(key);
    return cookie ? JSON.parse(cookie) : null; // 将JSON字符串转换回对象
}

// 移除临时缓存
export function removeSession(key) {
    Cookies.remove(key);
}

// 移除全部临时缓存
export function clearSession() {
    const cookies = Cookies.get();
    for (let key in cookies) {
        Cookies.remove(key);
    }
}