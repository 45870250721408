// 定义静态路由，动态路由(后端接口返回)及处理在 `utils/routesFilter.js` 中

export const staticRoutes = [
    {
        path: '/login',
        component: () => import('@/pages/login'),
        meta: {
            title: '登录',
        },
    },
    {
        path: '/404',
        component: () => import('@/pages/404'),
        meta: {
            title: '404',
        },
    }
]
