<template>
  <el-dialog :title="title" :visible.sync="is_show" size="small" :before-close="handleClose" append-to-body
             :width="width">
    <el-form size="small" :label-width="label_width">
      <slot name="form">
      </slot>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <slot name="btn">
      </slot>
      <el-button type="primary" size="small" @click="submit" :loading="loading">提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "app-diy-form",
  props: {
    width: {
      type: String,
    },
    label_width: {
      type: String,
      default: '100px'
    },
    formData: {},
    url: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    is_show: {
      type: Boolean,
      default: false
    },
    list: {},
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    handleClose(f) {
      this.$emit('close', f, this);
    },
    handleSubmit(rdata) {
      this.$emit('submit', rdata);
    },
    submit() {
      this.loading = true;
      this.$core.post(this.url, this.formData, rdata => {
        this.loading = false;
        this.handleSubmit(rdata);
        if (rdata.code != 0) {
          return this.$core.alert(rdata.msg);
        }
        this.handleClose(!1);
        return this.$core.message.success(rdata.msg);
      });
    }
  }
}
</script>

<style>
</style>
