import {post} from "@/api/index";


const message = {
    /**
     * 发布消息
     * @param params
     * @param callback
     * @param isShowTip
     * @returns {*|Promise<*>}
     */
    save(params, callback, isShowTip) {
        return post('/admin/message/save', params, callback, isShowTip);
    },
    /**
     * 获取消息列表
     * @param params
     * @param callback
     * @param isShowTip
     * @returns {*|Promise<*>}
     */
    get_list(params, callback, isShowTip) {
        return post('/admin/message/get-list', params, callback, isShowTip);
    }
}
export default message;
