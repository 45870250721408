import {post} from "@/api/index";

const article = {
    /**
     *
     * @param params
     * @param callback
     * @param isShowTip
     * @returns {*|Promise<*>}
     */
    get_list(params, callback, isShowTip) {
        return post('/admin/article/get-list', params, callback, isShowTip);
    },
    edit(params, callback, isShowTip) {
        return post('/admin/article/edit', params, callback, isShowTip);
    },
    detail(params, callback, isShowTip) {
        return post('/admin/article/detail', params, callback, isShowTip);
    },
    category: {
        edit(params, callback, isShowTip) {
            return post('/admin/article/cate-edit', params, callback, isShowTip);
        },
        get_list(params, callback, isShowTip) {
            return post('/admin/article/get-cate-list', params, callback, isShowTip);
        },
    }
}
export default article;