import {Message, MessageBox} from "element-ui";
import request from '@/utils/request'

const core = {
    message: {
        auto(rdata) {
            return rdata.code == 0 ? Message.success(rdata.msg) : Message.error(rdata.msg);
        },
        success(a) {
            Message.success(a)
        },
        error(a) {
            Message.error(a)
        }
    },
    /**
     *  信息框提示
     * @param {提示内容} content
     * @param {标题} title
     * @param {回调函数} cellback
     */
    alert: function (content, title, cellback) {
        MessageBox(content, (title) ? title : '提示', {
            confirmButtonText: '确定',
        }).then(() => {
            "function" == typeof cellback && cellback(true);
        }).catch(() => {
            "function" == typeof cellback && cellback(false);
        });
    },
    /**
     *  提示确认框
     * @param {*} content
     * @param {*} title
     * @param {*} cellback
     */
    confirm: function (content, title, cellback) {
        MessageBox.confirm(content, (title) ? title : '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            "function" == typeof cellback && cellback(true);
        }).catch(() => {
            "function" == typeof cellback && cellback(false);
        });
    },
    post: function (t, e, n, o, i) {
        this.json(t, e, n, o, !0, i);
    },
    get: function (t, e, n, o, i) {
        this.json(t, e, n, o, !1, i);
    },
    json: function (t, n, o, i, a, r) {
        const d = this;
        if (i) {
            var loading = Element.Loading.service({
                lock: true,
                text: (i == 1 || i == true) ? '加载中...' : i,
            });
        }
        request({
            url: this.getUrl(t),
            method: a ? "POST" : "GET",
            data: n ? n : {},
        }).then(function (response) {
            console.log(response)
            o(response);
        });
    },
    getUrl: function (n, o, i) {
        let a = siteInfo.siteroot + n;
        return o && ("object" == (0, t.default)(o) ? a += "&" + e.param(o) : "string" == typeof o && (a += "&" +
            o)),
            a;
    },
    /**
     *
     * @returns 获取配置
     */
    getSiteInfo: function () {
        return siteInfo;
    },
    /**
     *
     * @returns 获取域名
     */
    getdomain: function () {
        return siteInfo.siteroot;
    },
    getUrlParams() {
        let geturl = window.location.href;
        if (geturl.split('?').length <= 1) {
            return {};
        }
        let getqyinfo = geturl.split('?')[1];
        let obj = {}  //创建空对象，接收截取的参数
        let getqys = getqyinfo.split('&');
        for (let i = 0; i < getqys.length; i++) {
            let item = getqys[i].split('=')
            let key = item[0]
            let value = item[1]
            obj[key] = value
        }
        return obj;
    }
};
export default core;
