import {get, post} from "@/api/index";
import utils from "@/utils";

/**
 *  用户操作类
 */
const user = {
    get_papers(params, callback) {
        return get('/admin/user/get-papers', params, callback);
    },
    /**
     * 保存证书操作
     * @param params
     * @param callback
     * @returns {*|Promise<*>}
     */
    save_papers(params, callback, isTips) {
        return post('/admin/user/save-papers', params, callback,isTips);
    },
    /**
     * 用户登录
     * @param params
     * @param callback
     * @returns {Function|Promise<*>}
     */
    login(params, callback) {
        return post('/api/user/login', params, callback);
    },
    /**
     * 获取菜单
     * @param params
     * @param callback
     * @returns {Function|Promise<*>}
     */
    getMenus(params, callback) {
        const userInfo = utils.user.get();
        return post(userInfo.menuApiUrl, params, callback);
    },
    /**
     * 获取所有用户
     * @param params
     * @param callback
     * @returns {Function|Promise<*>}
     */
    getList(params, callback) {
        return post('/admin/user/get-list', params, callback);
    },
    /**
     * 处理用户数据
     * @param params is_add 新增
     * @param callback
     * @returns {Function|Promise<*>}
     */
    add(params, callback) {
        return post('/admin/user/add-user', params, callback);
    },
    /**
     * 处理用户数据
     * @param params is_add 新增
     * @param callback
     * @returns {Function|Promise<*>}
     */
    edit(params, callback) {
        return post('/admin/user/user-edit', params, callback);
    },
    /**
     *  获取用户所有信息
     * @param params
     * @param callback
     * @returns {*|Promise<*>}
     */
    getInfo(params, callback) {
        return post('/admin/user/get-user-info', params, callback);
    },
    /**
     * 修改密码
     * @param params
     * @param callback
     * @returns {*|Promise<*>}
     */
    editPassword(params, callback) {
        return post('/admin/user/edit-password', params, callback);
    }

}
export default user;
