<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import {getSession} from "@/utils/storage";

export default {
  name: "App",
  mounted() {
    this.initSession();
  },
  methods: {
    // 初始化系统布局配置，防止刷新的时候恢复默认状态
    initSession() {
      if (getSession("layoutConfig")) {
        this.$store.dispatch("setLayoutConfig", getSession("layoutConfig"));
      }
    },
  },
};
</script>
<style scoped>
/* 	.el-header {
  padding: 0px;
  box-sizing: border-box;
  -ms-flex-negative: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}

.el-card-foot {
  margin-top: 20px;
}

.el-dialog {
  position: relative;
  margin: 0 auto 50px;
  border-radius: 10px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, .3);
  box-shadow: 0 1px 3px rgba(0, 0, 0, .3);
  box-sizing: border-box;
  width: 40%;
} */
</style>
