import {post} from "@/api/index";

export default {
    upload_image: siteInfo.siteroot + "/api/utils/upload",
    /**
     * 获取系统全局配置
     * @param params
     * @param callback
     * @returns {*|Promise<*>}
     */
    getSetting(params, callback) {
        return post('/api/utils/get-setting', params, callback);
    },
    /**
     * 设置系统配置
     * @param params
     * @param callback
     * @returns {*|Promise<*>}
     */
    setSetting(params, callback) {
        return post('/api/utils/set-setting', params, callback);
    },
    /**
     * 清理系统缓存
     * @param params
     * @param callback
     * @param isTip
     * @returns {*|Promise<*>}
     */
    clearCache(params, callback, isTip) {
        return post('/api/utils/clear-cache', params, callback, isTip);
    },
    /**
     * 获取所有监控摄像头画面
     * @param params
     * @param callback
     * @param isTip
     * @returns {*|Promise<*>}
     */
    getDeviceAll(params, callback, isTip) {
        return post('/api/utils/get-device-all', params, callback, isTip);
    },
    /**
     * 操作设备
     * @param params
     * @param callback
     * @param isTip
     * @returns {*|Promise<*>}
     */
    setDevice(params, callback, isTip) {
        return post('/api/utils/set-device', params, callback, isTip);
    },
}
