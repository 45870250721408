import {post} from "@/api/index";

const admin = {
    package: {
        /**
         * 获取套餐列表
         * @param params
         * @param callback
         * @returns {*|Promise<*>}
         */
        get_list(params, callback) {
            return post('/admin/package/get-list', params, callback);
        },
        /**
         * 操作套餐
         * @param params
         * @param callback
         * @returns {*|Promise<*>}
         */
        save(params, callback) {
            return post('/admin/package/save', params, callback);
        }
    },
}
export default admin;