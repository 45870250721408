import {post} from "@/api/index";

const order = {
    /**
     *  操作提货订单
     * @param params
     * @param callback
     * @returns {*|Promise<*>}
     */
    set_rice_order(params, callback,isTip) {
        return post('/admin/order/set-rice', params, callback,isTip);
    },
    /**
     *  获取提货订单
     * @param params
     * @param callback
     * @returns {*|Promise<*>}
     */
    get_rice_list(params, callback) {
        return post('/admin/order/get-rice-list', params, callback);
    },
    /**
     *
     * @param params
     * @param callback
     * @returns {*|Promise<*>}
     */
    get_list(params, callback) {
        return post('/admin/order/get-list', params, callback);
    },
    /**
     * 更新处理订单
     * @param params
     * @param callback
     * @param isTip
     * @returns {*|Promise<*>}
     */
    submit(params, callback, isTip) {
        return post('/admin/order/submit', params, callback, isTip);
    },
    /**
     * 提交创建支付订单
     * @param params
     * @param callback
     * @param i
     * @returns {*}
     */
    submitPay(params, callback, i) {
        return post('/admin/order/submit-pay', params, callback, i);
    },
}
export default order;