<template>
  <el-card v-loading="loading" element-loading-text="加载中..." shadow="always" :bodyStyle="{padding:'10px'}">
    <div slot="header" v-if="search">
      <el-form size="small" :inline="true">
        <slot name="form">
        </slot>
      </el-form>
    </div>
    <el-table :data="tableData" border :height="height">
      <slot name="table">

      </slot>
    </el-table>
    <el-col :span="24" class="toolbar">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
                     layout="total,sizes,prev, pager, next" :total="FormData.page.count"
                     :current-page="FormData.page.page"
                     :page-size="FormData.page.limit" size="small"
                     style="float: right; margin-bottom: 15px; margin-top: 15px;">
      </el-pagination>
    </el-col>
  </el-card>
</template>

<script>
import {post} from "@/api";

export default {
  name: "app-table",
  props: {
    is_auto_load: {
      type: Boolean,
      default: true
    },
    height: {},
    search: {
      type: Boolean,
      default: false
    },
    FormData: {
      type: Object,
      default: function () {
        return {
          keyword: ""
        };
      }
    },
    url: {},
    request_type: {
      type: String,
      default: function () {
        return "post";
      }
    }
  },
  data() {
    return {
      tableData: [],
      loading: false,
    }
  },
  created() {
    this.FormData.page = {
      page: 1,
      limit: 10,
      count: 10
    };
    if(this.is_auto_load){
      this.getList();
    }
  },
  methods: {
    onSearch() {
      this.FormData.page = {
        page: 1,
        limit: 10,
        count: 10
      };
      this.getList();
    },
    handleSizeChange(val) {
      this.FormData.page.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.FormData.page.page = val;
      this.getList();
    },
    getList() {
      this.loading = true;
      this.$emit('loadBefore', this);
      post(this.url, this.FormData, rdata => {
        this.$emit('loadAfter', rdata);
        this.$emit('respon', rdata);
        this.loading = false;
        if (rdata.code != 0) {
          return void this.$message.error(rdata.msg);
        }
        this.tableData = rdata.data.list;
        this.FormData.page = rdata.page;
      });
    }
  }
}
</script>

<style scoped>
/* 	.el-form-item--small.el-form-item {
  margin-bottom: 0px;
} */

.el-card__header .el-form-item {
  margin-bottom: 0px;
}

/* 	.el-form>.el-form-item--small.el-form-item {
  margin-bottom: 0px;
}

.el-card__header {
  padding: 18px 20px;
  border-bottom: 1px solid #ebeef5;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
} */
</style>
