import store from '@/store'
import {resetRouter} from '@/router'
import api from "@/api";

// 处理后端返回的 `component` 路径，拼装实现懒加载
export function loadView(path) {
    return () => import(`@/pages/${path}`);
}

// 递归处理每一项 `component` 中的路径
export function dynamicRouter(view) {
    if (view.component) view.component = loadView(view.component)
    if (view.children) view.children.map(item => dynamicRouter(item))
    return view
}


// 添加动态路由，`{ path: '*', redirect: '/404' }` 防止页面刷新，静态路由丢失问题
// next({ ...to, replace: true }) 动态路由 addRoutes 完毕后才放行，防止刷新时 NProgress 进度条加载2次
// store.commit('setPrimeMenuData', JSON.parse(JSON.stringify(res))) 设置不处理 `component` 路径的原始值，用于菜单管理中显示(页面路径：用户/菜单管理)
export function loadMenus(router, to, next) {
    api.user.getMenus({}, res => {
        store.commit('setPrimeMenuData', JSON.parse(JSON.stringify(res.data)))
        resetRouter()
        store.commit('setMenuData', res.data)
       // router.addRoutes([dynamicRouter(res.data), {path: 'admin/seed/farmland-edit', redirect: '/404'}])
        router.addRoutes([dynamicRouter(res.data), {path: '*', redirect: '/404'}])
        next({...to, replace: true})
        console.log(router,'加载动态路由');
    });
}

