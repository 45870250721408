import user from "@/api/user"
import request from "@/utils/request";
import message from "@/api/message";
import core from "@/utils/core";
import admin from "@/api/admin";
import seed from "@/api/seed";
import utils from "@/api/utils";
import article from "@/api/article";
import order from "@/api/order";
import market from "@/api/market";
import attachment from "@/api/attachment";
import navigate from "@/api/navigate";

export default {
    user,
    message,
    admin,
    seed,
    utils,
    article,
    order,
    market,
    attachment,
    navigate
}
// const requireComponent = require.context('@/api', true, /\.js$/)
// requireComponent.keys().map(fileName => {
//     let path = name = fileName
//         .replace(/^\.\//, '')    // 移除文件名开头的相对路径
//         .replace(/\.\w+$/, '')   // 移除文件名的扩展名
//         .replace(/\//g, '/');    // 将斜杠转换为正确的路径分隔符
//     console.log(path,'文件名称');
// });

/**
 *
 * @param url
 * @param params
 * @param fn
 * @param isTip
 * @returns {*|Promise<*>}
 */
export function upload(url, params, fn, isTip = false) {
    if (typeof fn === 'function') {
        console.log(params,'上传数据');
        const result = request({
            headers: {'Content-Type': 'multipart/form-data'},
            url: url,
            method: 'post',
            data: params
        });
        return result.then(result => {
            if (isTip) {
                (result.code == 0) ? core.message.success(result.msg) : core.message.error(result.msg);
            }
            console.log(result, '111');
            return fn(result);
        })
    } else {

    }
    return url;
}

/**
 *
 * @param url
 * @param params
 * @param fn
 * @param isTip
 * @returns {*|Promise<*>}
 */
export function post(url, params, fn, isTip = false) {
    if (typeof fn === 'function') {
        const result = request({
            url: url,
            method: 'post',
            data: params
        });
        return result.then(result => {
            if (isTip) {
                (result.code == 0) ? core.message.success(result.msg) : core.message.error(result.msg);
            }
            console.log(result, '111');
            return fn(result);
        })
    } else {

    }
    return url;
}

/**
 *
 * @param url
 * @param params
 * @param Function fn
 * @returns {Function,Promise<*>}
 */
export async function get(url, params, fn, isTip = false) {
    const result = await request({
        url: url,
        method: 'get',
        data: params
    });
    return fn(result);
}
