import {post} from "@/api/index";

const market = {
    /**
     * 获取优惠券列表
     * @param params
     * @param callback
     * @param isShowTip
     * @returns {*|Promise<*>}
     */
    getCuponList(params, callback, isShowTip) {
        return post('/admin/market/get-cupon-list', params, callback, isShowTip);
    },
    /**
     * 获取会员列表
     * @param params
     * @param callback
     * @param isShowTip
     * @returns {*|Promise<*>}
     */
    getMembersList(params, callback, isShowTip) {
        return post('/admin/market/get-members-list', params, callback, isShowTip);
    },
    /**
     *  同步绑定会员等级列表
     * @param params
     * @param callback
     * @param isShowTip
     * @returns {*|Promise<*>}
     */
    syncMembersList(params, callback, isShowTip) {
        return post('/admin/market/sync-members-list', params, callback, isShowTip);
    }
}
export default market;
