<template>
  <el-drawer :title="title" :visible.sync="is_show" :direction="direction" :before-close="handleClose"
             @open="initList" :size="width">
    <el-card v-loading="loading" element-loading-text="加载中..." shadow="always" :bodyStyle="{padding:'10px'}">
      <div slot="header" v-if="search">
        <el-form size="small" :inline="true">
          <slot name="form">
          </slot>
        </el-form>
      </div>
      <el-table :data="tableData" border>
        <slot name="table">

        </slot>
      </el-table>
      <el-col :span="24" class="toolbar">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
                       layout="total,sizes,prev, pager, next" :total="page.count" :current-page="page.page"
                       :page-size="page.limit" size="small"
                       style="float: right; margin-bottom: 15px; margin-top: 15px;">
        </el-pagination>
      </el-col>
    </el-card>
    <!--    <el-form size="small" :inline="true">-->
    <!--      <slot name="form">-->
    <!--      </slot>-->
    <!--    </el-form>-->
    <!--    <el-table :data="tableData" border v-loading="loading" element-loading-text="加载中..." :height="table_height">-->
    <!--      <slot name="table">-->

    <!--      </slot>-->
    <!--    </el-table>-->
    <!--    <el-col :span="24" class="toolbar">-->
    <!--      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background-->
    <!--                     layout="total,sizes,prev, pager, next" :total="page.count" :current-page="page.page"-->
    <!--                     :page-size="page.limit" size="small" style="float: right; margin-bottom: 15px; margin-top: 15px;">-->
    <!--      </el-pagination>-->
    <!--    </el-col>-->
  </el-drawer>
</template>

<script>
import {post} from "@/api";

export default {
  name: "app-drawer-table",
  props: {
    table_height: {
      type: Number,
      //default: ''
    },
    title: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '30%'
    },
    direction: {
      type: String,
      default: 'ltr'
    },
    is_show: {
      type: Boolean,
      default: false
    },
    search: {
      type: Boolean,
      default: false
    },
    FormData: {
      type: Object,
      default: function () {
        return {
          keyword: "",
          page: {
            page: 1,
            limit: 10,
            count: 10
          }
        };
      }
    },
    url: {
      type: String
    },
    request_type: {
      type: String,
      default: function () {
        return "post";
      }
    }
  },
  data() {
    return {
      tableData: [],
      loading: false,
      page: {
        page: 1,
        limit: 10,
        count: 10
      },
      global_data: {}
    }
  },
  methods: {
    initList() {
      this.page = {
        page: 1,
        limit: 10,
        count: 10
      };
      this.getList();
    },
    handleClose() {
      this.$emit('close', this);
    },
    onSearch() {
      this.page = {
        page: 1,
        limit: 10,
        count: 10
      };
      this.getList();
    },
    handleSizeChange(val) {
      this.page.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page.page = val;
      this.getList();
    },
    getList() {
      this.loading = true;
      this.FormData.page = this.page;
      post(this.url, this.FormData, rdata => {
        this.global_data = rdata;
        this.loading = false;
        if (rdata.code != 0) {
          this.handleClose();
          return void this.$message.error(rdata.msg);
        }
        this.tableData = rdata.data.list;
        this.page = rdata.page;
      });
    }
  }
}
</script>

<style scoped>
/* 	.el-form-item--small.el-form-item {
  margin-bottom: 0px;
} */

.el-card__header .el-form-item {
  margin-bottom: 0px;
}

/* 	.el-form>.el-form-item--small.el-form-item {
  margin-bottom: 0px;
}

.el-card__header {
  padding: 18px 20px;
  border-bottom: 1px solid #ebeef5;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
} */
</style>
