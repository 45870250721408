import {post} from "@/api/index";

/**
 * 认种管理
 */
export default {
    /**
     * 分类设置
     */
    category: {
        get_list(params, callback, isShowTip) {
            return post('/admin/seed/get-list-category', params, callback, isShowTip);
        },
        edit(params, callback, isShowTip) {
            return post('/admin/seed/edit-category', params, callback, isShowTip);
        }
    },
    /**
     * 村委会
     */
    country: {
        get_list(params, callback, isShowTip) {
            return post('/admin/seed/get-list-country', params, callback, isShowTip);
        },
        edit(params, callback, isShowTip) {
            return post('/admin/seed/edit-country', params, callback, isShowTip);
        }
    },
    /**
     * 农田
     */
    goods: {
        get_list(params, callback, isShowTip) {
            return post('/admin/seed/get-list-goods', params, callback, isShowTip);
        },
        edit(params, callback, isShowTip) {
            return post('/admin/seed/edit-goods', params, callback, isShowTip);
        },
    },
    /**
     * 农户操作类
     */
    farmer: {
        /**
         * 获取所有用户
         * @param params
         * @param callback
         * @returns {*|Promise<*>}
         */
        get_list(params, callback, isShowTip) {
            return post('/admin/seed/get-list-farmer', params, callback, isShowTip);
        },
        edit(params, callback, isShowTip) {
            return post('/admin/seed/edit-farmer', params, callback, isShowTip);
        },
    }
}