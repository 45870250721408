import {clearLocal, getSession, setSession} from "@/utils/storage";

const user = {
    /**
     *  判断用户是否已登录
     * @returns {any|boolean}
     */
    isLogin() {
        return getSession("user") ? getSession("user") : false;
    },
    /**
     * 存储用户登录信息
     * @param params
     * @returns {boolean}
     */
    setLogin(params) {
        setSession("access_token", params.access_token);
        setSession("user", params);
        return true;
    },
    /**
     * 退出登录 清理所有缓存
     * @returns {boolean}
     */
    outLogin() {
        clearLocal();
        return true;
    },
    /**
     *  获取用户信息
     * @returns {any}
     */
    get() {
        return getSession("user");
    },
};
export default user;