import {post} from "@/api/index";

const navigate = {
    /**
     *
     * @param params
     * @param callback
     * @returns {*|Promise<*>}
     */
    get_list(params, callback) {
        return post('/admin/navigate/get-list', params, callback);
    },
    /**
     * 更新处理订单
     * @param params
     * @param callback
     * @param isTip
     * @returns {*|Promise<*>}
     */
    submit(params, callback, isTip) {
        return post('/admin/navigate/submit', params, callback, isTip);
    },
    /**
     * 编辑
     * @param params
     * @param callback
     * @param isTip
     * @returns {*|Promise<*>}
     */
    cate_list(params, callback, isTip){
        return post('/admin/navigate/cate-list', params, callback, isTip);
    },
    /**
     * 编辑
     * @param params
     * @param callback
     * @param isTip
     * @returns {*|Promise<*>}
     */
    cate_edit(params, callback, isTip){
        return post('/admin/navigate/cate-edit', params, callback, isTip);
    }
}
export default navigate;