import {post,upload} from "@/api/index";

/**
 * 资源组
 */
export default {
    /**
     * 删除资源
     * @param params
     * @param callback
     * @param isTip
     * @returns {*|Promise<*>}
     */
    delete(params,callback,isTip){
        return post('/admin/attachment/delete', params, callback, isTip);
    },
    /**
     * 移动资源
     * @param params
     * @param callback
     * @param isTip
     * @returns {*|Promise<*>}
     */
    move(params,callback,isTip){
        return post('/admin/attachment/move', params, callback, isTip);
    },
    /**
     * 上传资源
     * @param params
     * @param callback
     * @param isTip
     * @returns {*|Promise<*>}
     */
    upload(params, callback, isTip) {
        return upload('/admin/attachment/upload', params, callback, isTip);
    },
    /**
     * 增加分组
     * @param params
     * @param callback
     * @param isTip
     * @returns {*|Promise<*>}
     */
    edit_group(params, callback, isTip) {
        return post('/admin/attachment/edit-groups', params, callback, isTip);
    },
    /**
     * 获取分类
     * @param params
     * @param callback
     * @param isTip
     * @returns {*|Promise<*>}
     */
    group_list(params, callback, isTip) {
        return post('/admin/attachment/groups-list', params, callback, isTip);
    },
    /**
     * 获取资源列表
     * @param params
     * @param callback
     * @param isTip
     * @returns {*|Promise<*>}
     */
    get_list(params, callback, isTip) {
        return post('/admin/attachment/get-list', params, callback, isTip);
    },
}